<template>
  <div>
    <b-card-code
      :title="'Update Brand: ' + nameCat"
      :before-change="validationForm"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- image Cover -->
            <b-col md="6">
              <div align="center" class="text-center">
                <h4>Cover</h4>
                <b-media class="mb-1 mr-auto">
                  <b-avatar class="" ref="previewEl" :src="img" size="180px" />
                </b-media>
                <div class="mb-4">
                  <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @change="uploadImage"
                    />
                    <feather-icon icon="EditIcon" class="" />
                  </b-button>
                </div>
              </div>
            </b-col>
            <!-- image Icon -->
            <b-col md="6">
              <div align="center" class="text-center">
                <h4>Icon</h4>
                <b-media class="mb-1">
                  <b-avatar class="" ref="previewEl" :src="img2" size="180px" />
                </b-media>
                <div class="mb-4">
                  <b-button
                    variant="primary"
                    @click="$refs.refInputEl2.click()"
                  >
                    <input
                      ref="refInputEl2"
                      type="file"
                      class="d-none"
                      @change="uploadImage2"
                    />
                    <feather-icon icon="EditIcon" class="" />
                  </b-button>
                </div>
              </div>
            </b-col>
            <!-- active -->
            <b-col md="5">
              <validation-provider
                #default="{ errors }"
                name="Active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import { VueEditor } from 'vue2-editor'
import BCardCode from '@core/components/b-card-code'
import { required } from '@validations'
export default {
  components: {
    BCardCode,
    VueEditor,
  },
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      id: 0,
      img: '',
      img2: '',
      activeSelect: {
        text: '',
        value: '',
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      data: {
        active: '',
      },
      nameCat: '',
      errors_back: [],
      required,
    }
  },
  created() {
    // request caategoru data
    this.id = this.$route.params.id

    axios
      .get('brands/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.img = data.cover
        this.img2 = data.logo
        this.data = result.data.data
        this.nameCat = data.translation[0].name
        delete this.data.logo
        delete this.data.cover
        if (data.active == true) {
          this.activeSelect.value = '1'
          this.activeSelect.text = 'True'
        } else {
          this.activeSelect.value = '0'
          this.activeSelect.text = 'False'
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    // update function to brand
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.active = this.activeSelect.value
          if (this.img.includes('data:image/')) {
            this.data.cover = this.img.split(',').map((item) => item.trim())
            this.data.cover = this.data.cover[1]
          }
          if (this.img2.includes('data:image/')) {
            this.data.icon = this.img2.split(',').map((item) => item.trim())
            this.data.icon = this.data.icon[1]
          }

          axios
            .put('brands/' + this.id, this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage2(files[0])
    },
    createImage2(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img2 = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
 
<style lang="scss">

</style>


<style lang="scss" scoped>
</style>